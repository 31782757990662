import React from 'react'
import { Container, Navbar } from 'react-bootstrap'

const dateTime = new Date().getFullYear(); 

const Footer = () => {

  return (
		<Navbar fixed='bottom' bg='dark' variation='light'>
			<Container fluid>
				<div className='text-white py-2 mx-auto'>D.Ben-Toby &copy; {dateTime}</div>
				<span className='text-white'>
					{process.env.NODE_ENV} Mode {process.env.REACT_APP_API_ENDPOINT}
				</span>
			</Container>
		</Navbar>
	)
}


export default Footer