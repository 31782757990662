import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import ProtectedRoute from './Components/ProtectedRoutes'
import Login from './Containers/Login'

import Stores from './Containers/Stores'
import AddStore from './Containers/AddStorePage'
import StoreDetails from './Components/Stores/StoreDetails'

import Users from './Containers/Users'
import AddUser from './Containers/AddUserPage'
import UserDetails from './Components/Users/UserDetails'

import Products from './Containers/Products'
import AddProduct from './Containers/AddProductPage'
import ProductDetails from './Components/Products/ProductDetails'

import Orders from './Containers/Orders'
// import AddOrder from './Containers/AddOrderPage'
import OrderDetails from './Components/Orders/OrderDetails'
import PublicOrder from './Components/PublicOrder/PublicOrder'

const App = () => {
	const token = localStorage.getItem('token')

	return (
		<BrowserRouter>
			<div className='App'>
				<Switch>
					<Route exact path='/'>
						{token ? <Redirect to='/orders' /> : <Redirect to='/login' />}
					</Route>
					<Route exact path='/login' component={Login} />
					<ProtectedRoute exact path='/stores' component={Stores} />
					<ProtectedRoute exact path='/add-store' component={AddStore} />
					<ProtectedRoute exact path='/store/:storeId' component={StoreDetails} />

					<ProtectedRoute exact path='/users' component={Users} />
					<ProtectedRoute exact path='/add-user' component={AddUser} />
					<ProtectedRoute exact path='/user/:email' component={UserDetails} />

					<ProtectedRoute exact path='/products' component={Products} />
					<ProtectedRoute exact path='/add-product' component={AddProduct} />
					<ProtectedRoute exact path='/product/:itemNumber' component={ProductDetails} />

					<ProtectedRoute exact path='/orders' component={Orders} />
					{/* <ProtectedRoute exact path='/add-order' component={AddOrder} /> */}
					<ProtectedRoute exact path='/order/:itemNumber' component={OrderDetails} />
					<Route exact path='/orderlink/:itemNumber' component={PublicOrder} />
				</Switch>
			</div>
		</BrowserRouter>
	)
}

export default App
