import React, { useState } from 'react'
import axios from 'axios'
import { Button, Modal, Form, Col, Container } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'

const EditOrderForm = props => {
  const history = useHistory()

  const [order, setOrder] = useState({
    newOrderNumber: props.newOrderNumber,
    newOrderPoNumber: props.newOrderPoNumber,
    newUnitsDelivered: props.newUnitsDelivered,
    newCasesDelivered: props.newCasesDelivered,
    newTotalInvoice: props.newTotalInvoice,
    newTerms: props.newTerms,
    newStore: props.newStore,
    newProducts: props.newProducts,
    newNotes: props.newNotes,
  })

  const handleUnitsDelivered = event => {
    const unitsDelivered = event.target.value
    setOrder(order => {
      return { ...order, newUnitsDelivered: unitsDelivered }
    })
  }

  const handleCasesDelivered = event => {
    const casesDelivered = event.target.value
    setOrder(order => {
      return { ...order, newCasesDelivered: casesDelivered }
    })
  }

  const handleTotalInvoice = event => {
    const totalInvoice = event.target.value
    setOrder(order => {
      return { ...order, newTotalInvoice: totalInvoice }
    })
  }

  // const handleTerms = event => {
  // 	const terms = event.target.value
  // 	setOrder(order => {
  // 		return { ...order, newTerms: terms }
  // 	})
  // }

  const handleNotesInput = event => {
    const notes = event.target.value
    setOrder(order => {
      return { ...order, newNotes: notes }
    })
  }

  const handleProducts = event => {
    const products = event.target.value
    setOrder(product => {
      return { ...product, newProducts: products }
    })
  }

  const handleFormSubmit = event => {
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/api/orders/edit-order`, order, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(response => {
        history.push('/orders')
        window.alert('order was updated')
      })
      .catch(err => console.log(err))

    event.preventDefault()
  }

  return (
    <Container className='mt-5'>
      <Form onSubmit={handleFormSubmit} action='/'>
        <Form.Group controlId='formBasicEmail'>
          <Form.Row className='mb-3'>
            <Col>
              <Form.Label>Order Number {props.orderNumber}</Form.Label>
              <p></p>
              <Form.Label>by: {props.user}</Form.Label>
              {/* <Form.Control type='text' onChange={handleStoreIdInput} defaultValue={props.storeId} /> */}
            </Col>
            {/* <Col>
							<Form.Label>Order Po Number {props.orderPoNumber} </Form.Label>
							<Form.Control type='text' defaultValue={props.orderPoNumber} onChange={handleOrderNumber} />
						</Col> */}
          </Form.Row>
          <Form.Row className='mb-3'>
            {/* <Col>
							<Form.Label>Order Po Number</Form.Label>
							<Form.Control type='text' defaultValue={props.orderPoNumber} onChange={handleOrderPoNumber} />
						</Col> */}
            <Col>
              <Form.Label>Units delivered</Form.Label>
              <Form.Control type='text' defaultValue={props.unitsDelivered} onChange={handleUnitsDelivered} />
            </Col>
            <Col>
              <Form.Label>Cases delivered</Form.Label>
              <Form.Control type='text' defaultValue={props.casesDelivered} onChange={handleCasesDelivered} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Label>Total Invoice</Form.Label>
              <Form.Control type='text' defaultValue={props.totalInvoice} onChange={handleTotalInvoice} />
            </Col>
            <Col>
              <Form.Label>Products</Form.Label>
              <Form.Control type='text' defaultValue={props.product} onChange={handleProducts} />
            </Col>
          </Form.Row>
          <Form.Label className='mt-3'> Notes </Form.Label>
          <Form.Control as='textarea' rows='3' defaultValue={props.notes} onChange={handleNotesInput} />
        </Form.Group>
        {/* <Form.Check label='Active?' onChange={handleActiveInput} defaultChecked={props.active} /> */}
        <Form.Group className='my-3'>
          <Button size='md' type='submit'>
            Update Order
          </Button>
        </Form.Group>
      </Form>
    </Container>
  )
}

const OrderEdit = props => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button variant='primary' onClick={handleShow}>
        Edit Order
      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Update Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditOrderForm
            orderNumber={props.orderNumber}
            orderPoNumber={props.orderPoNumber}
            unitsDelivered={props.unitsDelivered}
            casesDelivered={props.casesDelivered}
            totalInvoice={props.totalInvoice}
            notes={props.notes}
            user={props.user}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrderEdit
