import React, { useState, useEffect } from 'react'
import { Form, ListGroup, Row, Table, Button } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useBarcode } from '@createnextapp/react-barcode'

import Navigation from '../Navigation'
import OrderEdit from './OrderEdit'
import Footer from '../Footer'

const OrderDetails = props => {
  const [order, setOrder] = useState({})
  const [loadingOrder, setLoadingOrder] = useState(false)
  const [store, setStore] = useState({})
  const [invoiceNumber, setInvoiceNumber] = useState()

  const storeId = order?.store
  let orderNumber = props.match.params.itemNumber

  const Barcode = item => {
    const { inputRef } = useBarcode({
      value: item.upc,
      options: {
        // background: '#ffff00',
      },
    })

    return <img ref={inputRef} alt={inputRef} />
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/orders/order?id=${orderNumber}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(res => {
        setOrder(res.data)
        setLoadingOrder(true)
      })
  }, [orderNumber])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/stores/store?id=${storeId}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(res => {
        setStore(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [storeId])

  const sendInvoiceNumber = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/orders/add-invoiceno`,
        { orderNumber: orderNumber, invoiceNumber: invoiceNumber },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then(() => window.alert('order was updated'))
      .catch(err => console.error(err))
  }

  const unitTotals = []
  console.log(storeId)

  return (
    <>
      <Navigation />
      <div className='mt-4 invoice-table' style={{ width: '300px' }}>
        <Row md={12} className='justify-content-between'>
          <ListGroup>
            <ListGroup.Item>
              D. Ben-Toby, Inc
              <br />
              8307 NW 54 Street <br />
              Doral, Florida 33166 <br />
              <br /> <h4> Vendor #2L333</h4>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup>
            <ListGroup.Item>
              <b>Store #:</b> {store?.storeId}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Store Phone:</b> {store?.storePhone}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Receiving dates:</b> {store?.deliveryDays}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Manager:</b> {store?.storeManager}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Terms:</b> 30 days
            </ListGroup.Item>
          </ListGroup>
          <ListGroup>
            <ListGroup.Item>
              <b>Invoice No: {order.invoiceNumber}</b>

              {!order.invoiceNumber && (
                <Form inline>
                  <Form.Label htmlFor='invoiceNumber' srOnly>
                    Invoice Number
                  </Form.Label>
                  <Form.Control
                    className='mb-2 mr-sm-2'
                    id='invoiceNumber'
                    placeholder='ex. I-33435'
                    onChange={e => setInvoiceNumber(e.target.value)}
                  />
                  <Button onClick={() => sendInvoiceNumber()} type='submit' className='mb-2' disabled={invoiceNumber ? false : true}>
                    Save
                  </Button>
                </Form>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Date:</b> {moment(order.date).format('l')}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Order #:</b> {order.orderNumber}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Units Delivered:</b> {order.unitsDelivered}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Cases Delivered:</b> {order.casesDelivered}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Total Invoice: </b>
              <NumberFormat value={order.totalInvoice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Table striped bordered className='mt-4'>
          <thead>
            <tr>
              <th>#</th>
              <th>Item Number</th>
              <th>UPC</th>
              <th>CVS#</th>
              <th>Description</th>
              <th>Pack</th>
              <th>Type</th>
              <th>CVS delivered cost per unit</th>
              <th>Cases Delivered</th>
              <th>Units Delivered</th>
              <th>Cost Delivered</th>
            </tr>
          </thead>
          <tbody>
            {loadingOrder &&
              order.products.map(item => {
                const lineTotal = item.units * item.price
                unitTotals.push(item.units)

                return (
                  <tr>
                    <td>1</td>
                    <td>{item.itemNumber}</td>
                    <td>
                      <Barcode upc={item.upc} />
                    </td>
                    <td>{item.cvsNumber}</td>
                    <td>{item.description}</td>
                    <td>{item.pack}</td>
                    <td>{item.productType}</td>
                    <td>
                      <NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </td>
                    <td>{item.cases}</td>
                    <td>{item.units}</td>
                    <td>
                      <NumberFormat value={lineTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
        <Row className='justify-content-between'>
          <Table>
            <thead>
              <tr>
                <th>Store# </th>
                <th>Address</th>
                <th>Phone #</th>
                <th>Receiving Dates</th>
                <th>Signed by</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{store?.storeId}</td>
                <td>{store?.storeAddress}</td>
                <td>{store?.storePhone}</td>
                <td>{store?.deliveryDays}</td>
                <td>
                  <img style={{ width: '100px', height: '200px' }} src={order.signature} alt='' />
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Button onClick={() => window.print()} className='mr-2'>
          PRINT
        </Button>
        <OrderEdit
          orderNumber={order.orderNumber}
          orderPoNumber={order.orderPoNumber}
          unitsDelivered={order.unitsDelivered}
          casesDelivered={order.casesDelivered}
          totalInvoice={order.totalInvoice}
          terms={order.terms}
          notes={order.notes}
          store={order.store}
          user={order.user}
        />
      </div>
      <Footer />
    </>
  )
}

export default OrderDetails
