import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Card } from 'react-bootstrap'

import Navigation from '../Navigation'
import UserEditModal from './UserEditForm'
import Footer from '../Footer'

const UserDetails = props => {
	const [user, setUser] = useState({})
	let email = props.match.params.email

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/user?id=${email}`, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(res => {
				// console.log(res)
				setUser({
					email: res.data.email,
					firstName: res.data.firstName,
					lastName: res.data.lastName,
					userType: res.data.userType,
					createdDate: res.data.createdDate,
					active: res.data.active,
					notes: res.data.notes,
				})
			})
			.catch(err => {
				console.log(err)
			})
	}, [email])

	return (
		<>
			<Navigation />
			<Container className='mt-3'>
				<Card style={{ width: '600px', margin: '0 auto' }}>
					<Card.Header>
						{user.firstName} {user.lastName}
					</Card.Header>
					<Card.Body>
						<Card.Text>
							<b>User Email: </b>
							{user.email}
						</Card.Text>
						<Card.Text>
							<b>User Type: </b>
							{user.userType}
						</Card.Text>
						<Card.Text>
							<b>Notes: </b>
							{user.notes}
						</Card.Text>
						<Card.Text>
							<b>Active: </b>
							{user.active ? 'yes' : 'no '}
						</Card.Text>
						<Card.Text>
							<b>Created Date: </b> {user.createdDate}
						</Card.Text>
						<UserEditModal
							email={email}
							firstName={user.firstName}
							lastName={user.lastName}
							userType={user.userType}
							active={user.active}
							notes={user.notes}
						/>
					</Card.Body>
				</Card>
			</Container>
			<Footer />
		</>
	)
}

export default UserDetails
