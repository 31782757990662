import React, { useState } from 'react'
import { Container, Form, Button, Col } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const AddUserForm = () => {
	const history = useHistory()

	const [user, setUser] = useState({
		email: '',
		password: '',
		userType: '',
		firstName: '',
		lastName: '',
		notes: ''
	})


	const handleEmailInput = event => {
		const userEmail = event.target.value
		setUser(email => {
			return { ...email, email: userEmail }
		})
	}

	const handlePasswordInput = event => {
		const userPassword = event.target.value
		setUser(store => {
			return { ...store, password: userPassword }
		})
	}

	const handleFirstNameInput = event => {
		const userFirstName = event.target.value
		setUser(store => {
			return { ...store, firstName: userFirstName }
		})
	}

	const handleLastNameInput = event => {
		const userLastName = event.target.value
		setUser(store => {
			return { ...store, lastName: userLastName }
		})
	}

	const handleUserTypeInput = event => {
		const userType = event.target.value
		setUser(store => {
			return { ...store, userType: userType }
		})
	}

	const handleNotesInput = event => {
		const notes = event.target.value
		setUser(store => {
			return { ...store, notes: notes }
		})
	}

	const handleFormSubmit = event => {
		// console.log(store)
		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT}/api/users/add-user`, user, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				console.log(response)
				history.push('/users')
				window.alert('user was added')
			})
			.catch(err => {
				console.log(err)
			})

		event.preventDefault()
	}

	return (
		<Container className='mt-5'>
			<Form onSubmit={handleFormSubmit} action='/'>
				<Form.Group controlId='formBasicEmail'>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> Email </Form.Label>
							<Form.Control type='text' placeholder='ex. jonGarc@gmail.com' onChange={handleEmailInput} />
						</Col>
						<Col>
							<Form.Label> Password </Form.Label>
							<Form.Control type='text' placeholder='ex. P@ssworD' onChange={handlePasswordInput} />
						</Col>
					</Form.Row>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> First Name </Form.Label>
							<Form.Control type='text' placeholder='ex. Jon' onChange={handleFirstNameInput} />
						</Col>
						<Col>
							<Form.Label> Last Name </Form.Label>
							<Form.Control type='text' placeholder='ex. Garcia' onChange={handleLastNameInput} />
						</Col>
						<Col>
							<Form.Group controlId='exampleForm.ControlSelect1'>
								<Form.Label> User Type </Form.Label>
								<Form.Control as='select' onChange={handleUserTypeInput}>
									<option>Select Type</option>
									<option value='sales'>sales</option>
									<option value='customer'>customer</option>
									<option value='admin'>admin</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Form.Row>

					<Form.Label className='mt-3'> Notes </Form.Label>
					<Form.Control as='textarea' rows='3' placeholder='Notes here' onChange={handleNotesInput} />
				</Form.Group>
				<Form.Group className='my-3'>
					<Button size='md' type='submit' disabled={!user.userType || !user.email} >
						Add User
					</Button>
				</Form.Group>
			</Form>
		</Container>
	)
}

export default AddUserForm
