import React, { useState } from 'react'
import { Container, Form, Button, Col } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const AddProductForm = () => {
	const history = useHistory()

	const [product, setProduct] = useState({
		itemNumber: '',
		upc: '',
		cvsNumber: '',
		description: '',
		pack: '',
		price: '',
	})

	const handleItemNumberInput = event => {
		const itemNumber = event.target.value
		setProduct(product => {
			return { ...product, itemNumber: itemNumber }
		})
	}

	const handleUpcInput = event => {
		const upc = event.target.value
		setProduct(product => {
			return { ...product, upc: upc }
		})
	}

	const handleCvsInput = event => {
		const cvsNumber = event.target.value
		setProduct(product => {
			return { ...product, cvsNumber: cvsNumber }
		})
	}

	const handlePriceInput = event => {
		const price = event.target.value
		setProduct(product => {
			return { ...product, price: price }
		})
	}

	const handleDescriptionInput = event => {
		const description = event.target.value
		setProduct(product => {
			return { ...product, description: description }
		})
	}

	const handlePackInput = event => {
		const pack = event.target.value
		setProduct(product => {
			return { ...product, pack: pack }
		})
	}

	const handleCategoryInput = event => {
		const category = event.target.value
		setProduct(product => {
			return { ...product, category: category }
		})
	}

	const handleBrandInput = event => {
		const brand = event.target.value
		setProduct(product => {
			return { ...product, brand: brand }
		})
	}

	const handleImageInput = event => {
		const image = event.target.value
		setProduct(product => {
			return { ...product, image: image }
		})
	}

	const handleProductTypeInput = event => {
		const productType = event.target.value
		setProduct(product => {
			return { ...product, productType: productType }
		})
	}

	const handleNotesInput = event => {
		const notes = event.target.value
		setProduct(product => {
			return { ...product, notes: notes }
		})
	}

	const handleFormSubmit = event => {
		console.log(product)
		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT}/api/products/add-product`, product, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				console.log(response)
				history.push('/products')
				window.alert('Product was added')
			})
			.catch(err => {
				console.log(err)
			})

		event.preventDefault()
	}

	return (
		<Container className='mt-5'>
			<Form onSubmit={handleFormSubmit} action='/'>
				<Form.Group controlId='formBasicEmail'>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> Item Number </Form.Label>
							<Form.Control type='text' placeholder='ex. AH-10861' onChange={handleItemNumberInput} />
						</Col>
						<Col>
							<Form.Label> Upc </Form.Label>
							<Form.Control type='number' placeholder='ex. 76440108613' onChange={handleUpcInput} />
						</Col>
						<Col>
							<Form.Label> Cvs Number </Form.Label>
							<Form.Control type='number' placeholder='ex. 764401' min='0' onChange={handleCvsInput} />
						</Col>
					</Form.Row>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> Description </Form.Label>
							<Form.Control type='text' placeholder='ex. 2PC PIE VALUE PACK.' onChange={handleDescriptionInput} />
						</Col>
						<Col>
							<Form.Label> Price </Form.Label>
							<Form.Control type='number' placeholder='ex. 2.65'  step='any' onChange={handlePriceInput} />
						</Col>
						<Col>
							<Form.Label> Pack </Form.Label>
							<Form.Control type='number' placeholder='ex. 2' min='0' onChange={handlePackInput} />
						</Col>
					</Form.Row>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> Category</Form.Label>
							<Form.Control type='text' placeholder='ex. 110v' onChange={handleCategoryInput} />
						</Col>
						<Col>
							<Form.Label> Brand</Form.Label>
							<Form.Control type='text' placeholder='ex. Black & Decker' onChange={handleBrandInput} />
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Label> Image</Form.Label>
							<Form.Control
								type='text'
								placeholder='ex. http://www.dbentoby.com/images/item.jpg'
								onChange={handleImageInput}
							/>
						</Col>
						<Form.Group controlId='exampleForm.ControlSelect1'>
							<Form.Label>Product Type</Form.Label>
							<Form.Control as='select' onChange={handleProductTypeInput}>
								<option>Select Type</option>
								<option value='storeOnly'>Store Only</option>
								<option value='dualSource'>Dual Source</option>
								<option value='warehouseOnly'>DC - Warehouse Only</option>
							</Form.Control>
						</Form.Group>
					</Form.Row>

					<Form.Label className='mt-3'> Notes </Form.Label>
					<Form.Control as='textarea' rows='3' placeholder='Notes here' onChange={handleNotesInput} />
				</Form.Group>
				<Form.Group className='my-3'>
					<Button size='md' type='submit'>
						Add Product
					</Button>
				</Form.Group>
			</Form>
		</Container>
	)
}

export default AddProductForm
