import React, { useState } from 'react'
import axios from 'axios'
import { Button, Modal, Form, Col, Container } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'

const UserEditForm = props => {
	const history = useHistory()

	const [user, setUser] = useState({
		email: props.email,
		newEmail: props.email,
		newFirstName: props.firstName,
		newLastName: props.lastName,
		newUserType: props.userType,
		newNotes: props.notes,
		newActive: props.active,
	})

	// console.log(user)
	
	const handleEmailInput = event => {
		const email = event.target.value
		setUser(user => {
			return { ...user, newEmail: email }
		})
	}

	const handleFirstNameInput = event => {
		const firstName = event.target.value
		setUser(user => {
			return { ...user, newFirstName: firstName }
		})
		
	}

	const handleLastNameInput = event => {
		const lastName = event.target.value
		setUser(user => {
			return { ...user, newLastName: lastName }
		})
	}

	const handleUserTypeInput = event => {
		const userType = event.target.value
		setUser(user => {
			return { ...user, newUserType: userType }
		})
	}

	const handleActiveInput = event => {
		let active = event.target.checked
		console.log(active)
		setUser(user => {
			return { ...user, newActive: active }
		})
	}

	const handleNotesInput = event => {
		const notes = event.target.value
		setUser(user => {
			return { ...user, newNotes: notes }
		})
	}

	// const handlePasswordInput = event => {
	// 	const password = event.target.value
	// 	setUser(user => {
	// 		return { ...user, newPassword: password }
	// 	})
	// }

	const handleFormSubmit = event => {
		// console.log(store)

		axios
			.put(`${process.env.REACT_APP_API_ENDPOINT}/api/users/edit-user`, user, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				// console.log(response)
				history.push('/users')
				window.alert('user was updated')
			})
			.catch(err => {
				console.log(err)
			})

		event.preventDefault()
	}

	return (
		<Container className='mt-5'>
			<Form onSubmit={handleFormSubmit} action='/'>
				<Form.Group controlId='formBasicEmail'>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label>Email / Username :</Form.Label>
							<Form.Control type='text' onChange={handleEmailInput} defaultValue={props.email} />
						</Col>
						<Col>
							<Form.Label>First Name: {props.firstName} </Form.Label>
							<Form.Control type='text' defaultValue={props.firstName} onChange={handleFirstNameInput} />
						</Col>
						<Col>
							<Form.Label>Last Name: </Form.Label>
							<Form.Control type='text' defaultValue={props.lastName} onChange={handleLastNameInput} />
						</Col>
					</Form.Row>
					<Form.Row className='mb-3'>
						{/* <Col>
							<Form.Label>New Password</Form.Label>
							<Form.Control type='text' onChange={handlePasswordInput} />
						</Col> */}
						<Col>
							<Form.Group controlId='exampleForm.ControlSelect1'>
								<Form.Label>User Type: </Form.Label>
								<Form.Control as='select' onChange={handleUserTypeInput} defaultValue={props.userType}>
									<option value={props.userType}> {props.userType}</option>
									{props.userType !== 'sales' && <option value='sales'>sales</option>}
									{props.userType !== 'customer' && <option value='customer'>customer</option>}
									{props.userType !== 'admin' && <option value='admin'>admin</option>}
								</Form.Control>
							</Form.Group>
						</Col>
					</Form.Row>

					<Form.Label className='mt-3'> Notes </Form.Label>
					<Form.Control as='textarea' rows='3' defaultValue={props.notes} onChange={handleNotesInput} />
				</Form.Group>
				<Form.Check label='Active?' onChange={handleActiveInput} defaultChecked={props.active} />
				<Form.Group className='my-3'>
					<Button size='md' type='submit'>
						Update User
					</Button>
				</Form.Group>
			</Form>
		</Container>
	)
}

const UserEditModal = props => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	// console.log(props)

	return (
		<>
			<Button variant='primary' onClick={handleShow}>
				Edit User
			</Button>

			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Update User {props.email}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<UserEditForm
						email={props.email}
						firstName={props.firstName}
						lastName={props.lastName}
						userType={props.userType}
						notes={props.notes}
						active={props.active}
					/>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default UserEditModal
