import React, { useState } from 'react'
import { Container, Form, Button, Navbar } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import Footer from '../Components/Footer'

const Login = () => {
	const history = useHistory()
	const [gotError, setGotError] = useState(false)
	const [login, setLogin] = useState({
		email: '',
		password: '',
	})

	const handleEmailLogin = event => {
		const email = event.target.value
		setLogin(originalLogin => {
			return { ...originalLogin, email: email }
		})
	}

	const handlePasswordLogin = event => {
		const password = event.target.value
		setLogin(originalPassword => {
			return { ...originalPassword, password: password }
		})
		// console.log(login)
	}

	const handleLoginForm = event => {
		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT}/signin`, login)
			.then(response => {
				// console.log(response)s

				if (response.data.user.userType === 'admin') {
					localStorage.setItem('token', response.data.token)
					history.push('/orders')
				} else {
					setGotError(true)
				}
				// console.log(response)
			})
			.catch(err => {
				setGotError(true)
				// console.log(err)
			})
		event.preventDefault()
	}

	return (
		<>
			<Navbar bg='dark' variant='dark'>
				<Navbar.Brand>D.BenToby Sales Backoffice</Navbar.Brand>
			</Navbar>
			<Container>
				<Form className='mt-5' onSubmit={handleLoginForm} action='/stores'>
					<Form.Group controlId='formGroupEmail'>
						<Form.Label>Email address</Form.Label>
						<Form.Control type='email' placeholder='Enter email' onChange={handleEmailLogin} />
					</Form.Group>
					<Form.Group controlId='formGroupPassword'>
						<Form.Label>Password</Form.Label>
						<Form.Control type='password' placeholder='Password' onChange={handlePasswordLogin} />
					</Form.Group>
					<Button type='submit'>Login</Button>
					{gotError && <span className='text-danger ml-3'>Account / Password Invalid</span>}
				</Form>
			</Container>
			<Footer />
		</>
	)
}

export default Login
