import React, { useState } from 'react'
import { Container, Form, Button, Col } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const AddStoreForm = () => {
	const history = useHistory()

	const [store, setStore] = useState({
		storeId: '',
		storeAddress: '',
		storePhone: '',
		deliveryHours: '',
		deliveryDays: '',
		storeManager: '',
		terms: '',
		forklift: true,
		active: true,
		notes: '',
		contactOne: '',
		contactTwo: '',
		class: '',
	})

	const handleStoreIdInput = event => {
		const storeId = event.target.value
		setStore(store => {
			return { ...store, storeId: storeId }
		})
	}

	const handleStoreAddressInput = event => {
		const storeAddress = event.target.value
		setStore(store => {
			return { ...store, storeAddress: storeAddress }
		})
	}

	const handleStorePhoneInput = event => {
		const storePhone = event.target.value
		setStore(store => {
			return { ...store, storePhone: storePhone }
		})
	}

	const handleDeliveryHoursInput = event => {
		const deliveryHours = event.target.value
		setStore(store => {
			return { ...store, deliveryHours: deliveryHours }
		})
	}

	const handleDeliveryDaysInput = event => {
		const deliveryDays = event.target.value
		setStore(store => {
			return { ...store, deliveryDays: deliveryDays }
		})
	}

	const handleStoreManagerIdInput = event => {
		const storeManager = event.target.value
		setStore(store => {
			return { ...store, storeManager: storeManager }
		})
	}

	const handleForkliftInput = event => {
		// console.log(event.target.checked)
		let forklift = event.target.checked

		setStore(store => {
			return { ...store, forklift: forklift }
		})
	}

	const handleActiveInput = event => {
		// console.log(event.target.checked)
		let active = event.target.checked

		setStore(store => {
			return { ...store, active: active }
		})
	}

	const handleNotesInput = event => {
		const notes = event.target.value
		setStore(store => {
			return { ...store, notes: notes }
		})
	}

	const handleContactOneInput = event => {
		const contactOne = event.target.value
		setStore(store => {
			return { ...store, contactOne: contactOne }
		})
	}

	const handleContactTwoInput = event => {
		const contactTwo = event.target.value
		setStore(store => {
			return { ...store, contactTwo: contactTwo }
		})
	}

	const handleClassInput = event => {
		const storeClass = event.target.value
		setStore(store => {
			return { ...store, class: storeClass }
		})
	}

	const handleFormSubmit = event => {
		// console.log(store)

		axios
			.post(`${process.env.REACT_APP_API_ENDPOINT}/api/stores/add-store`, store, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				// console.log(response)
				history.push('/stores')
				window.alert('store was added')
			})
			.catch(err => {
				console.log(err)
			})

		event.preventDefault()
	}

	return (
		<Container className='mt-5'>
			<Form onSubmit={handleFormSubmit} action='/'>
				<Form.Group controlId='formBasicEmail'>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> Store ID </Form.Label>
							<Form.Control type='text' placeholder='ex. CVS-12343' onChange={handleStoreIdInput} />
						</Col>
						<Col>
							<Form.Label> Store Address </Form.Label>
							<Form.Control
								type='text'
								placeholder='ex. 12345 SW 123 ST, MIAMI FL 33454'
								onChange={handleStoreAddressInput}
							/>
						</Col>
					</Form.Row>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label> Store Phone </Form.Label>
							<Form.Control type='text' placeholder='ex. 305-456-5454' onChange={handleStorePhoneInput} />
						</Col>
						<Col>
							<Form.Label> Delivery Hours </Form.Label>
							<Form.Control type='text' placeholder='ex. 7pm' onChange={handleDeliveryHoursInput} />
						</Col>
						<Col>
							<Form.Label> Delivery Days </Form.Label>
							<Form.Control type='text' placeholder='ex. M, TH' onChange={handleDeliveryDaysInput} />
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Label> Store Manager </Form.Label>
							<Form.Control
								type='text'
								placeholder='ex. John Doe / 305-456-5434'
								onChange={handleStoreManagerIdInput}
							/>
						</Col>
						<Col>
							<Form.Label>Store First Contact</Form.Label>
							<Form.Control type='text' placeholder='ex. Jane Doe / 305-545-4544' onChange={handleContactOneInput} />
						</Col>
						<Col>
							<Form.Label>Store Second Contact</Form.Label>
							<Form.Control type='text' placeholder='ex. Mark Doe / 304-343-3434' onChange={handleContactTwoInput} />
						</Col>
						<Col>
							<Form.Label>Class</Form.Label>
							<Form.Control type='text' placeholder='ex. SAA' onChange={handleClassInput} />
						</Col>
					</Form.Row>
					<Form.Label className='mt-3'> Notes </Form.Label>
					<Form.Control as='textarea' rows='3' placeholder='Notes here' onChange={handleNotesInput} />
				</Form.Group>
				<Form.Check label='Store has Forklift?' onChange={handleForkliftInput} defaultChecked />
				<Form.Check label='Active' onChange={handleActiveInput} defaultChecked />
				<Form.Group className='my-3'>
					<Button size='md' type='submit'>
						Add Store
					</Button>
				</Form.Group>
			</Form>
		</Container>
	)
}

export default AddStoreForm
