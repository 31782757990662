import React from 'react'
import { useHistory } from 'react-router-dom'
import { Navbar, Nav, Button } from 'react-bootstrap'

const Navigation = () => {
  const history = useHistory()
  
  const handleLogout = () => { 
    localStorage.removeItem('token')
    history.push('/login')
  }

  return (
		<Navbar bg='dark' variant='dark' stick='top'>
			<Nav className='mr-auto'>
				<Nav.Link href='/stores'> Stores </Nav.Link>
				<Nav.Link href='/orders'> Orders </Nav.Link>
				<Nav.Link href='/users'> Users </Nav.Link>
				<Nav.Link href='/products'> Products </Nav.Link>
			</Nav>
			<Button inline onClick={handleLogout}>
				LOGOUT
			</Button>
		</Navbar>
	)
}

export default Navigation
