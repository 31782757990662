import React, { useState, useEffect } from 'react'
import { Nav, Navbar, Table, Button, Form, FormControl, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import imagena from '../assets/img/imagena.png'

const Products = () => {
  const [products, setproducts] = useState([{}])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [search, setSearch] = useState('')

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/products`, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(response => {
        console.log(response)
        setproducts(response.data)
        setLoadingProducts(false)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleSearchProduct = event => {
    setSearch(event.target.value.toUpperCase())
  }

  const filteredProducts = !loadingProducts
    ? products.filter(product => {
        return (
          (product.brand && product.brand.toLowerCase().includes(search.toLowerCase())) ||
          product.itemNumber.toLowerCase().includes(search.toLowerCase()) ||
          product.cvsNumber.toLowerCase().includes(search.toLowerCase())
        )
      })
    : []

  return (
    <>
      <Navigation />
      <Navbar bg='light' variant='light'>
        <Form inline>
          <FormControl type='text' placeholder='Search' className='mr-sm-2' onChange={handleSearchProduct} />
        </Form>
        <Button href='/add-product'>Add Product</Button>
      </Navbar>

      <div className='mx-5'>
        <Nav activeKey='/home' onSelect={selectedKey => alert(`Add a Product? `)}>
          {/* <Nav.Item>
						<Nav.Link eventKey='link-1'></Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey='link-2'>Link</Nav.Link>
					</Nav.Item> */}
        </Nav>
        <Table striped bordered className='mt-4'>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Item Number</th>
              <th>UPC</th>
              <th>CVS #</th>
              <th>Description</th>
              <th>Brand</th>
              <th>Pack</th>
              <th>Special</th>
              <th>Status</th>
              <th>Type</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {loadingProducts && (
              <Spinner animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            )}
            {filteredProducts
              .sort((a, b) => (a.active > b.active ? -1 : 1))
              .map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <img src={product.image ? product.image : imagena} width='100px' alt={product.itemNumber}></img>
                  </td>
                  <td>
                    <Link to={'/product/' + product.itemNumber}>{product.itemNumber} </Link>{' '}
                  </td>
                  <td>{product.upc}</td>
                  <td>{product.cvsNumber}</td>
                  <td>{product.description}</td>
                  <td>{product.brand}</td>
                  <td>{product.pack}</td>
                  <td>{product.special ? 'Yes' : 'No'}</td>
                  <td>{product.active ? 'Active' : 'Inactive'}</td>
                  <td>{product.productType}</td>
                  <td>
                    ${parseFloat(product.price).toFixed(2)}
                    {/* <NumberFormat
                      value={product.price}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    /> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Footer />
    </>
  )
}

export default Products
