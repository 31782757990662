import React from 'react'
import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import AddUserForm from '../Components/Users/AddUserForm'
import { Container } from 'react-bootstrap'

const AddUserPage = () => {
	return (
		<>
			<Navigation />
			<Container>
				<AddUserForm />
			</Container>
			<Footer />
		</>
	)
}

export default AddUserPage
