import React, { useState, useEffect } from 'react'
import { Container, ListGroup, Row, Table, Button } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useBarcode } from '@createnextapp/react-barcode'

// import Navigation from '../Navigation'
// import OrderEdit from './OrderEdit'
// import Footer from '../Footer'

const OrderDetails = props => {
	const [order, setOrder] = useState({})
	const [loadingOrder, setLoadingOrder] = useState(false)
	let orderNumber = props.match.params.itemNumber

	const Barcode = item => {
		console.log(item.upc)
		const { inputRef } = useBarcode({
			value: item.upc,
			options: {
				// background: '#ffff00',
			},
		})

		return <img ref={inputRef} alt={inputRef} />
	}

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/orders/order?id=${orderNumber}`, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(res => {
				console.log(res)

				setOrder({
					orderNumber: res.data.orderNumber,
					date: res.data.createdAt,
					orderPoNumber: res.data.orderPoNumber,
					unitsDelivered: res.data.unitsDelivered,
					casesDelivered: res.data.casesDelivered,
					totalInvoice: res.data.totalInvoice,
					terms: res.data.terms,
					store: res.data.store,
					products: res.data.products,
					notes: res.data.notes,
					user: res.data.user,
				})
				setLoadingOrder(true)
			})
	}, [orderNumber])

	// console.log(order.products)

	return (
		<>
			{/* <Navigation /> */}
			<Container className='mt-4 '>
				<Row md={12} className='justify-content-between'>
					<ListGroup>
						<ListGroup.Item>
							D. Ben-Toby, Inc
							<br />
							8307 NW 54 Street <br />
							Doral, Florida 33166 <br />
							<br /> <p> Vendor #2l333</p>
						</ListGroup.Item>
					</ListGroup>
					<ListGroup>
						<ListGroup.Item>
							<b>Invoice No:</b> {order.orderNumber}
						</ListGroup.Item>
						<ListGroup.Item>
							<b>Date:</b> {moment(order.date).format('l')}
						</ListGroup.Item>
						<ListGroup.Item>
							<b>Order PO/#:</b> {order.orderPoNumber}
						</ListGroup.Item>
						<ListGroup.Item>
							<b>Units Delivered:</b> {order.unitsDelivered}
						</ListGroup.Item>
						<ListGroup.Item>
							<b>Cases Delivered:</b> {order.casesDelivered}
						</ListGroup.Item>
						<ListGroup.Item>
							<b>Total Invoice:</b>
							<NumberFormat value={order.totalInvoice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
						</ListGroup.Item>
						<ListGroup.Item>
							<b>Terms:</b> {order.terms}
						</ListGroup.Item>
					</ListGroup>
				</Row>
				<Table striped bordered className='mt-4'>
					<thead>
						<tr>
							<th>#</th>
							<th>Item Number</th>
							<th>UPC</th>
							<th>CVS#</th>
							<th>Description</th>
							<th>Pack</th>
							<th>CVS delivered cost per unit</th>
							<th>Cases Delivered</th>
							<th>Units Delivered</th>
							<th>Cost Delivered</th>
						</tr>
					</thead>

					<tbody>
						{loadingOrder &&
							order.products.map(item => {
								const lineTotal = item.units * item.price

								return (
									<tr>
										<td>1</td>
										<td>{item.itemNumber}</td>
										<td>
											<Barcode upc={item.upc} />
										</td>
										<td>{item.cvsNumber}</td>
										<td>{item.description}</td>
										<td>{item.pack}</td>
										<td>
											<NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
										</td>
										<td>{item.cases}</td>
										<td>{item.units}</td>
										<td>
											<NumberFormat
												value={lineTotal.toFixed(2)}
												displayType={'text'}
												thousandSeparator={true}
												prefix={'$'}
											/>
										</td>
									</tr>
								)
							})}
					</tbody>
				</Table>
				<Row className='justify-content-between'>
					<strong> Store# {order.store}</strong>
					<div>
						<Button onClick={() => window.print()} className='mr-2'>
							PRINT
						</Button>
						{/* <Button onClick={() => alert('Send via email')}>EMAIL</Button> */}
					</div>
				</Row>
				{/* <OrderEdit
					orderNumber={order.orderNumber}
					orderPoNumber={order.orderPoNumber}
					unitsDelivered={order.unitsDelivered}
					casesDelivered={order.casesDelivered}
					totalInvoice={order.totalInvoice}
					terms={order.terms}
					notes={order.notes}
					store={order.store}
					user={order.user}
				/> */}
			</Container>
			{/* <Footer /> */}
		</>
	)
}

export default OrderDetails
