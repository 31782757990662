import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar, Table, Form, FormControl } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'

const Orders = () => {
	const [orders, setOrders] = useState([{}])
	const [loadingOrders, setLoadingOrders] = useState(true)
	const [search, setSearch] = useState('')

	console.log(search)

  useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/orders`, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				console.log(response.data)
				setOrders(response.data)
				setLoadingOrders(false)
			})
			.catch(err => {
				console.log(err)
			})
	}, [])
	
	const handleSearchOrder = event => {
		setSearch(event.target.value)
	}

	const filteredOrders = !loadingOrders
		? orders.filter(order => {
					return order.orderNumber.toString().includes(search)
		  })
		: []
  
  return (
		<>
			<Navigation />
			<Navbar bg='light' variant='light'>
				<Form inline>
					<FormControl
						type='text'
						placeholder='Search'
						className='mr-sm-2'
						onChange={event => handleSearchOrder(event)}
					/>
				</Form>
			</Navbar>

			<Container>
				<Nav activeKey='/home' onSelect={selectedKey => alert(`Add a Product? `)}></Nav>
				<Table striped bordered className='mt-4'>
					<thead>
						<tr>
							<th>#</th>
							<th>Order Number</th>
							<th>Store Number</th> 
							<th>Date</th>
							<th>Cases Delivered</th>
							<th>Units Delivered</th>
							<th>Received by</th>
							<th>total Invoice</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrders
						.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1 )
						.map((order, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									<Link to={'/order/' + order.orderNumber}>{order.orderNumber} </Link>
								</td>
								<td>{order.store}</td> 
								<td>{moment(order.createdAt).format('l')}</td>
								<td>{order.casesDelivered}</td>
								<td>{order.unitsDelivered}</td>
								<td>{order.receivedBy}</td>
								<td>
									<NumberFormat value={order.totalInvoice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Container>
			<Footer />
		</>
	)
}

export default Orders
