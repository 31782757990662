import React, { useState, useEffect } from 'react'
import { Container, Form, FormControl, Nav, Button, Navbar, Table, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'

const Users = () => {
	const [users, setUsers] = useState([{}])
	const [loadingUsers, setLoadingUsers] = useState(true)
	const [search, setSearch] = useState('')

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/users`, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				// console.log(response)
				setUsers(response.data)
				setLoadingUsers(false)
			})
			.catch(err => {
				console.log(err)
			})
	}, [])

	const handleSearchUser = event => {
		setSearch(event.target.value)
	}

	const filteredUsers = !loadingUsers
		? users.filter(user => {
				return user.email.toLowerCase().includes(search.toLowerCase())
		  })
		: []

	return (
		<>
			<Navigation />
			<Navbar bg='light' variant='light'>
				<Form inline>
					<FormControl type='text' placeholder='Search' className='mr-sm-2' onChange={handleSearchUser} />
					<Button href='/add-user'>Add User</Button>
				</Form>
			</Navbar>
			<Container>
				<Nav activeKey='/add-user'></Nav>
				<Table striped bordered className='mt-4'>
					<thead>
						<tr>
							<th>#</th>
							<th>User Email</th>
							<th>Name</th>
							<th>Type</th>
							<th>Active</th>
							<th>Created on</th>
						</tr>
					</thead>
					<tbody>
						{loadingUsers && (
							<Spinner animation='border' role='status'>
								<span className='sr-only'>Loading...</span>
							</Spinner>
						)}
						{filteredUsers.map((user, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									<Link to={'/user/' + user.email}>{user.email}</Link>
								</td>
								<td>{user.firstName && user.firstName + ' ' + user.lastName}</td>
								<td>{user.userType}</td>
								<td>{user.active ? 'Yes' : 'No'}</td>
								<td>{user.createdDate}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Container>
			<Footer />
		</>
	)
}

export default Users
