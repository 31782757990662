import React, { useState, useEffect } from 'react'
import { Container, Button, Navbar, Table, Form, FormControl, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'

const Stores = () => {
	const [stores, setStores] = useState([{}])
	const [loadingStores, setLoadingStores] = useState(true)
	const [search, setSearch] = useState('')

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/stores`, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}}
			)
			.then(response => {
				console.log(response)
				setStores(response.data)
				setLoadingStores(false)
			})
			.catch(err => {
				console.log(err)
			})
	}, [])

	const handleSearchStore = event => {
		setSearch(event.target.value)
	}

	const filteredStores = !loadingStores
		? stores.filter(store => {
				return store.storeId.toLowerCase().includes(search.toLowerCase())
		  })
		: []

	return (
		<>
			<Navigation />
			<Navbar bg='light' variant='light'>
				<Form inline>
					<FormControl
						type='text'
						placeholder='Search'
						className='mr-sm-2'
						onChange={event => handleSearchStore(event)}
					/>
				</Form>
				<Button href='/add-store'>Add Store</Button>
			</Navbar>

			<Container>
				<Table striped bordered className='mt-4'>
					<thead>
						<tr>
							<th>#</th>
							<th>Image</th>
							<th>Id</th>
							<th>Address</th>
							<th>Phone</th>
							<th>Active?</th>
						</tr>
					</thead>
					<tbody>
						{loadingStores && (
							<Spinner animation='border' role='status'>
								<span className='sr-only'>Loading...</span>
							</Spinner>
						)}

						{filteredStores.map((store, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									<img
										src='https://www.gannett-cdn.com/-mm-/b2b05a4ab25f4fca0316459e1c7404c537a89702/c=0-0-1365-768/local/-/media/2019/05/01/USATODAY/usatsports/247WallSt.com-247WS-544629-imageforentry19.jpg?width=660&height=372&fit=crop&format=pjpg&auto=webp'
										width='100px'
										alt={store.storeId}
									></img>
								</td>
								<td>
									<Link to={'/store/' + store.storeId}>{store.storeId}</Link>
								</td>
								<td>{store.storeAddress}</td>
								<td>{store.storePhone}</td>
								<td>{store.active ? 'Yes' : 'No'}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Container>
			<Footer />
		</>
	)
}

export default Stores
