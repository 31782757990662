import React, { useState } from 'react'
import axios from 'axios'
import { Button, Modal, Form, Col, Container } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'

const EditStoreForm = props => {
	const history = useHistory()

	const [store, setStore] = useState({
		storeId: props.storeId,
		// newStoreId: '',
		newStoreAddress: props.storeAddress,
		newStorePhone: props.storePhone,
		newDeliveryHours: props.deliveryHours,
		newDeliveryDays: props.deliveryDays,
		newStoreManager: props.storeManager,
		newForklift: true,
		newNotes: props.notes,
		newContactOne: props.contactOne,
		newContactTwo: props.contactTwo,
		class: props.class,
		newActive: true,
		newTerms: props.terms
	})

	const handleStoreAddressInput = event => {
		const storeAddress = event.target.value
		setStore(store => {
			return { ...store, newStoreAddress: storeAddress }
		})
	}

	const handleStorePhoneInput = event => {
		const storePhone = event.target.value
		setStore(store => {
			return { ...store, newStorePhone: storePhone }
		})
	}

	const handleDeliveryHoursInput = event => {
		const deliveryHours = event.target.value
		setStore(store => {
			return { ...store, newDeliveryHours: deliveryHours }
		})
	}

	const handleDeliveryDaysInput = event => {
		const deliveryDays = event.target.value
		setStore(store => {
			return { ...store, newDeliveryDays: deliveryDays }
		})
	}

	const handleStoreManagerIdInput = event => {
		const storeManager = event.target.value
		setStore(store => {
			return { ...store, newStoreManager: storeManager }
		})
	}

	const handleTermsInput = event => {
		const terms = event.target.value
		setStore(store => {
			return { ...store, newTerms: terms }
		})
	}

	const handleForkliftInput = event => {
		let forklift = event.target.checked
		setStore(store => {
			return { ...store, newForklift: forklift }
		})
	}

	const handleActiveInput = event => {
		let active = event.target.checked
		setStore(store => {
			return { ...store, newActive: active }
		})
	}

	const handleNotesInput = event => {
		const notes = event.target.value
		setStore(store => {
			return { ...store, newNotes: notes }
		})
	}

	const handleContactOneInput = event => {
		const contactOne = event.target.value
		setStore(store => {
			return { ...store, newContactOne: contactOne }
		})
	}

	const handleContactTwoInput = event => {
		const contactTwo = event.target.value
		setStore(store => {
			return { ...store, newContactTwo: contactTwo }
		})
	}

	const handleClassInput = event => {
		const storeClass = event.target.value
		setStore(store => {
			return { ...store, newClass: storeClass }
		})
	}

	const handleFormSubmit = event => {
		axios
			.put(`${process.env.REACT_APP_API_ENDPOINT}/api/stores/edit-store`, store, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(response => {
				// console.log(response)
				history.push('/stores')
				window.alert('store was updated')
			})
			.catch(err => {
				console.log(err)
				// console.log(store)
			})

		event.preventDefault()
	}

	return (
		<Container className='mt-5'>
			<Form onSubmit={handleFormSubmit} action='/'>
				<Form.Group controlId='formBasicEmail'>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label>Store ID {props.storeId}</Form.Label>
							{/* <Form.Control type='text' onChange={handleStoreIdInput} defaultValue={props.storeId} /> */}
						</Col>
						<Col>
							<Form.Label>Store Address {props.storeAddress} </Form.Label>
							<Form.Control type='text' defaultValue={props.storeAddress} onChange={handleStoreAddressInput} />
						</Col>
					</Form.Row>
					<Form.Row className='mb-3'>
						<Col>
							<Form.Label>Store Phone</Form.Label>
							<Form.Control type='text' defaultValue={props.storePhone} onChange={handleStorePhoneInput} />
						</Col>
						<Col>
							<Form.Label>Delivery Hours</Form.Label>
							<Form.Control type='text' defaultValue={props.deliveryHours} onChange={handleDeliveryHoursInput} />
						</Col>
						<Col>
							<Form.Label>Delivery Days</Form.Label>
							<Form.Control type='text' defaultValue={props.deliveryDays} onChange={handleDeliveryDaysInput} />
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Label>Store Manager</Form.Label>
							<Form.Control type='text' defaultValue={props.storeManager} onChange={handleStoreManagerIdInput} />
						</Col>
						<Col>
							<Form.Label>Store First Contact</Form.Label>
							<Form.Control type='text' defaultValue={props.contactOne} onChange={handleContactOneInput} />
						</Col>
						<Col>
							<Form.Label>Store Second Contact</Form.Label>
							<Form.Control type='text' defaultValue={props.contactTwo} onChange={handleContactTwoInput} />
						</Col>
						<Col>
							<Form.Label>Class</Form.Label>
							<Form.Control type='text' defaultValue={props.class} onChange={handleClassInput} />
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Label>Terms</Form.Label>
							<Form.Control type='text' defaultValue={props.terms} onChange={handleTermsInput} />
						</Col>
					</Form.Row>
					<Form.Label className='mt-3'> Notes </Form.Label>
					<Form.Control as='textarea' rows='3' defaultValue={props.notes} onChange={handleNotesInput} />
				</Form.Group>
				<Form.Check label='Store has Forklift?' onChange={handleForkliftInput} defaultChecked />
				<Form.Check label='Active?' onChange={handleActiveInput} defaultChecked={props.active} />
				<Form.Group className='my-3'>
					<Button size='md' type='submit'>
						Update Store
					</Button>
				</Form.Group>
			</Form>
		</Container>
	)
}

const StoreEdit = props => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	return (
		<>
			<Button variant='primary' onClick={handleShow}>
				Edit Store
			</Button>

			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Update Store</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditStoreForm
						storeId={props.storeId}
						storeAddress={props.storeAddress}
						storePhone={props.storePhone}
						storeManager={props.storeManager}
						contactOne={props.contactOne}
						contactTwo={props.contactTwo}
						class={props.class}
						forklift={props.forklift}
						notes={props.notes}
						deliveryDays={props.deliveryDays}
						deliveryHours={props.deliveryHours}
						active={props.active}
						terms={props.terms}
					/>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default StoreEdit
