import React from 'react'
import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import AddProductForm from '../Components/Products/AddProductForm'
import { Container} from 'react-bootstrap'

const AddProductpage = () => {
	return (
		<>
			<Navigation />
			<Container>
				<AddProductForm />
			</Container>
			<Footer />
		</>
	)
}

export default AddProductpage