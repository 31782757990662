import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Card } from 'react-bootstrap'

import Navigation from '../Navigation'
import StoreEdit from './StoreEdit'
import Footer from '../Footer'

const StoreDetails = props => {
	const [store, setStore] = useState({})
	let storeId = props.match.params.storeId

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_ENDPOINT}/api/stores/store?id=${storeId}`, {
				headers: {
					'content-type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then(res => {
				// console.log(res)
				setStore({
					contactOne: res.data.contactOne,
					contactTwo: res.data.contactTwo,
					class: res.data.class,
					storeManager: res.data.storeManager,
					forklift: res.data.forklift,
					notes: res.data.notes,
					storeAddress: res.data.storeAddress,
					storeId: res.data.storeId,
					storePhone: res.data.storePhone,
					deliveryDays: res.data.deliveryDays,
					deliveryHours: res.data.deliveryHours,
					active: res.data.active,
					terms: res.data.terms,
				})
			})
			.catch(err => {
				console.log(err)
			})
	}, [storeId])

	return (
		<>
			<Navigation />
			<Container className='mt-3'>
				<Card style={{ width: '600px', margin: '0 auto' }}>
					<Card.Header>{store.storeId}</Card.Header>
					<Card.Img
						variant='top'
						src='https://www.gannett-cdn.com/-mm-/b2b05a4ab25f4fca0316459e1c7404c537a89702/c=0-0-1365-768/local/-/media/2019/05/01/USATODAY/usatsports/247WallSt.com-247WS-544629-imageforentry19.jpg?width=660&height=200&fit=crop&format=pjpg&auto=webp'
					/>
					<Card.Body>
						<Card.Title>{store.storeAddress}</Card.Title>
						<Card.Text>
							<b>Phone: </b>
							{store.storePhone}
						</Card.Text>
						<Card.Text>
							<b>Store Manager: </b>
							{store.storeManager}
						</Card.Text>
						<Card.Text>
							<b>Contact One: </b>
							{store.contactOne}
						</Card.Text>
						<Card.Text>
							<b>Contact Two: </b>
							{store.contactTwo}
						</Card.Text>
						<Card.Text>
							<b>Store Class: </b>
							{store.class}
						</Card.Text>
						<Card.Text>
							<b>Delivery Hours: </b>
							{store.deliveryHours}
						</Card.Text>
						<Card.Text>
							<b>Delivery Days: </b>
							{store.deliveryDays}
						</Card.Text>
						<Card.Text>
							<b>ForkLift: </b>
							{store.forklift ? 'Yes' : 'No'}
						</Card.Text>
						<Card.Text>
							<b>Active: </b>
							{store.active ? 'Yes' : 'No'}
						</Card.Text>
						<Card.Text>
							<b>Notes: </b>
							{store.notes}
						</Card.Text>
						<Card.Text>
							<b>Store Terms: </b>
							{store.terms}
						</Card.Text>

						<StoreEdit
							storeId={storeId}
							storeAddress={store.storeAddress}
							storePhone={store.storePhone}
							storeManager={store.storeManager}
							contactOne={store.contactOne}
							contactTwo={store.contactTwo}
							class={store.class}
							deliveryDays={store.deliveryDays}
							deliveryHours={store.deliveryHours}
							forklift={store.forklift}
							terms={store.terms}
							notes={store.notes}
							active={store.active}
						/>
					</Card.Body>
				</Card>
			</Container>
			<Footer />
		</>
	)
}

export default StoreDetails
