import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Button, Table, Form } from 'react-bootstrap'

import Navigation from '../Navigation'
import Footer from '../Footer'
import imagena from '../../assets/img/imagena.png'

const ProductDetails = props => {
  const [product, setProduct] = useState({})
  const [newProduct, setNewProduct] = useState({})
  let itemNumber = props.match.params.itemNumber

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/products/product?id=${itemNumber}`, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(res => {
        console.log('here is data', res.data)
        setProduct(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [itemNumber])

  useEffect(() => {
    setNewProduct({
      itemNumber: product.itemNumber,
      newUpc: product.upc,
      newCvsNumber: product.cvsNumber,
      newDescription: product.description,
      newPack: product.pack,
      newPrice: product.price,
      newRetailPrice: product.retailPrice,
      newCategory: product.category,
      newBrand: product.brand,
      newImage: product.image,
      newProductType: product.productType,
      newNotes: product.notes,
      newSpecial: product.special,
      newActive: product.active,
    })
  }, [product])

  console.log(newProduct)

  const handleFormSubmit = event => {
    axios
      .patch(`${process.env.REACT_APP_API_ENDPOINT}/api/products/edit-product`, newProduct, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(response => {
        // console.log(response)
        // history.push('/products')
        window.alert('item was updated')
      })
      .catch(err => {
        console.log(err)
      })

    event.preventDefault()
  }

  return (
    <>
      <Navigation />
      <Container className='mt-3'>
        <img
          src={
            product.image
              ? product.image
              : imagena
          }
          width='100px'
          alt={product.itemNumber}
        ></img>
        <Table striped bordered hover className='mt-3' style={{ maxWidth: '600px' }}>
          <thead>
            <tr>
              <th>
                <b>Item Number: </b>
              </th>
              <th>{newProduct.itemNumber}</th>
            </tr>
            <tr>
              <th>
                <b>UPC: </b>
              </th>
              <th>
                <Form.Control
                  name='upcNumber'
                  type='text'
                  defaultValue={newProduct.newUpc}
                  onChange={e => setNewProduct({ ...newProduct, newUpc: e.target.value })}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>CVS Number: </b>
              </td>
              <td>
                <Form.Control
                  name='cvsNumber'
                  type='text'
                  defaultValue={newProduct.newCvsNumber}
                  onChange={e => setNewProduct({ ...newProduct, newCvsNumber: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Description: </b>
              </td>
              <td>
                {newProduct.description}
                <Form.Control
                  name='description'
                  type='text'
                  defaultValue={newProduct.newDescription}
                  onChange={e => setNewProduct({ ...newProduct, newDescription: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Pack: </b>
              </td>
              <td>
                <Form.Control
                  name='newPack'
                  type='number'
                  defaultValue={newProduct.newPack}
                  onChange={e => setNewProduct({ ...newProduct, newPack: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Price: </b>
              </td>
              <td>
                <Form.Control
                  name='newPrice'
                  type='number'
                  min='0'
                  defaultValue={newProduct.newPrice}
                  onChange={e => setNewProduct({ ...newProduct, newPrice: parseFloat(e.target.value) })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Retail Price: </b>
              </td>
              <td>
                <Form.Control
                  name='newRetailPrice'
                  min='0'
                  type='number'
                  defaultValue={newProduct.newRetailPrice}
                  onChange={e => setNewProduct({ ...newProduct, newRetailPrice: parseFloat(e.target.value) })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Brand: </b>
              </td>
              <td>
                <Form.Control
                  name='newBrand'
                  type='text'
                  value={newProduct.newBrand}
                  onChange={e => setNewProduct({ ...newProduct, newBrand: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Product Type: </b>
              </td>
              <td>
                <Form.Control
                  name='newProductType'
                  type='text'
                  defaultValue={newProduct.newProductType}
                  onChange={e => setNewProduct({ ...newProduct, newProductType: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Category: </b>
              </td>
              <td>
                <Form.Control
                  name='newCategory'
                  type='text'
                  defaultValue={newProduct.newCategory}
                  onChange={e => setNewProduct({ ...newProduct, newCategory: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Image: </b>
              </td>
              <td>
                <Form.Control
                  name='newCategory'
                  type='text'
                  defaultValue={newProduct.newImage}
                  onChange={e => setNewProduct({ ...newProduct, newImage: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Notes: </b>
              </td>
              <td>
                <Form.Control
                  name='newNotes'
                  as='textarea'
                  rows='3'
                  defaultValue={newProduct.newNotes}
                  onChange={e => setNewProduct({ ...newProduct, newNotes: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Special: </b>
              </td>
              <td>
                <Form.Check onChange={e => setNewProduct({ ...newProduct, newSpecial: e.currentTarget.checked })} checked={newProduct.newSpecial} />
              </td>
            </tr>

            <tr>
              <td>
                <b>Active: </b>
              </td>
              <td>
                <Form.Check onChange={e => setNewProduct({ ...newProduct, newActive: e.currentTarget.checked })} checked={newProduct.newActive} />
              </td>
            </tr>
            {/* <tr>
              <td>
                <b>Created by: </b>
              </td>
              <td>
                <b>Por: </b>
                {product.createdBy}&nbsp; <b>Date:</b> {moment(product.createdAt).format('LLL')}
              </td>
            </tr>
            <tr>
              <td>
                <b>Modificado: </b>
              </td>
              <td>
                <b>Por: </b>
                {product.modifiedBy}&nbsp; <b>Date:</b> {moment(product.updatedAt).format('LLL')}
              </td>
            </tr> */}
          </tbody>
        </Table>

        <Button size='md' type='submit' onClick={handleFormSubmit}>
          Update Product
        </Button>
      </Container>
      <Footer />
    </>
  )
}

export default ProductDetails
