import React from 'react'
import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import AddStoreForm from '../Components/Stores/AddStoreForm'
import { Container } from 'react-bootstrap'


const AddStorePage = () => {

  return (
    <>
    <Navigation /> 
    <Container> 
      <AddStoreForm /> 
    </Container> 
    <Footer /> 
    </> 
  )
}

export default AddStorePage 